.webview {
  font-family: "SVN-Gotham Bold" !important;
  color: "#4A4A4A" !important;
}

.webview span {
  width: 100% !important;
  height: 100% !important;
  font-size: 18px; 
}

.webview img {
  object-fit: contain;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .webview img {
    width: auto;
    height: auto;
  }
}

.webview ol,
.webview ul {
  padding-inline-start: 0 !important;
  font-size: 18px;
  /* list-style-type: none; */
}

.webview p {
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
}
.webview div {
  margin-bottom: 12px;
  font-size: 16px;
}
/*.webview p br {
  display: none;
}
.webview p:has(br) {
  display: none;
}*/

.webview h1,
.webview h2,
.webview h3,
.webview h4 {
  margin-bottom: 8px;
  margin-top: 12px;
}
.webview h1 {
  font-family: "SVN-Gotham Bold" !important;
  font-size: 20px !important;
  font-weight: 900;
  line-height: 24px;
}

.webview h2 {
  font-family: "SVN-Gotham Bold" !important;
  font-size: 18px !important;
  font-weight: 900;
  line-height: 22px;
}

.webview h3 {
  font-family: "SVN-Gotham Bold" !important;
  font-size: 16px !important;
  font-weight: 900;
  line-height: 20px;
}

.webview h4 {
  font-family: "SVN-Gotham Bold" !important;
  font-size: 16px !important;
  font-weight: 900;
  line-height: 20px;
}

/* .webview h1 {
  font-size: 24px;
}
.webview h2 {
  font-size: 22px;
}
.webview h3 {
  font-size: 20px;
}
.webview h4 {
  font-size: 18px;
} */
.webview img {
  margin-bottom: 10px;
}
.webview ol li,
.webview ul li {
  margin-bottom: 3px;
  margin-left: 18px;
}
.webview ul li p {
  margin-bottom: 0px;
  margin-left: 18px;
}

.webview td {
  vertical-align: middle !important;
}

.webview div:has(> table) {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
